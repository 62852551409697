const getAria = (name) => {
  let ariaLabel;

  switch (name) {
    case 'twitter':
      ariaLabel = 'Will on Twitter';
      break;
    case 'github':
      ariaLabel = 'Will on GitHub';
      break;
    case 'linkedin':
      ariaLabel = 'Will on LinkedIn';
      break;
    case 'telegram':
      ariaLabel = 'Will on Telegram';
      break;
    case 'email':
      ariaLabel = 'Send an email to Will';
      break;
    default:
      ariaLabel = 'Will Neill';
      break;
  }

  return ariaLabel;
};

export default getAria;
