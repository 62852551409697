const getAlt = (name, contact) => {
  let alt;

  switch (name) {
    case 'twitter':
      alt = `${contact} on Twitter`;
      break;
    case 'github':
      alt = `${contact} on GitHub`;
      break;
    case 'linkedin':
      alt = `${contact} on LinkedIn`;
      break;
    case 'telegram':
      alt = `${contact} on Telegram`;
      break;
    case 'email':
      alt = 'Send an email to Will Neill';
      break;
    default:
      alt = contact;
      break;
  }

  return alt;
};

export default getAlt;
