import React from 'react';
import Helmet from 'react-helmet';
import styles from './Layout.module.scss';

const Layout = ({ children, title, description }) => (
  <div className={styles.layout}>
    <Helmet>
      <html lang="en" />
      <title>{title}</title>
      <meta name="description" content={description} />
      {/* Load Google Analytics */}
      <script async src="https://www.googletagmanager.com/gtag/js?id=UA-75627027-1"></script>
      <script type="text/javascript">{`
        var gtagId = 'UA-75627027-1';
        window['ga-disable-' + gtagId] = true;
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'gtagId', { 'anonymize_ip': true });
      `}
      </script>
      {/* Load Cookie Management */}
      <script type="text/javascript" src="https://cookiehub.net/cc/a1bb36f7.js?version=1" />
      <script type="text/javascript">{`
        window.addEventListener("load", function() {
        window.cookieconsent.initialise({
          onInitialise: function(status) {
            if (this.hasConsented('required')) {
            }
            if (this.hasConsented('analytics')) {
              window['ga-disable-UA-75627027-1'] = false;
              gtag('config', gtagId);
            }
            if (this.hasConsented('marketing')) {
            }
          },
          onAllow: function(category) {
            if (category === 'required') {
            }
            if (category === 'analytics') {
              window['ga-disable-UA-75627027-1'] = false;
              gtag('config', gtagId);
            }
            if (category === 'marketing') {
            }
          },
          onRevoke: function(category) {
            if (category === 'required') {
            }
            if (category === 'analytics') {
              window['ga-disable-UA-75627027-1'] = true;
            }
            if (category === 'marketing') {
            }
          }
        })
        });
      `}
      </script>
    </Helmet>
    {children}
  </div>
);

export default Layout;
